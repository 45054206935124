import React from "react"
import { Helmet } from "react-helmet"

import { Layout } from "../components/Layout"
import "rc-tooltip/assets/bootstrap.css"
import Lottie from "react-lottie"
import animationDataGif from "../../static/img/gifIris.json"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import {
    ServiceSection,
    MainVideo,
    DemoButton,
    LearnMoreButton,
    CountsSection,
    AttributeSection,
    IntegrationsSection,
    ContactSection,
    HomeFeaturedSection,
    Testimonials,
    ProductSummaryWidget,
    AutoScrollBanner,
    GetStartedButtonWhite,
    VimeoPlayer,
    TryIrisGPTForFreeBanner,
    FeatureSection,
    FeatureSectionRight,
} from "../commonComponents"

import {
  IntegrationDarkSection
} from "../integrationsUtils"

export default function Home() {
  const TitleImage = () => {
    return (
      <div className="video-player-wrapper" >
        <VimeoPlayer url="https://vimeo.com/1065588168" autoplay="true" loop="true" />
      </div>
    )
  }

  const Video = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationDataGif,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    }
    return (
      <div className="video-background video-hero-new-design">
        <Lottie options={defaultOptions} />
      </div>
    )
  }

  return (
    <Layout
        classes={{
            main: "section-spacing"
        }}
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link rel="canonical" href="https://irisagent.com/" />
      </Helmet>

      <section
        id="hero-new-design"

      >
        <div className="container w-100 mw-100 m-0">
            <div className={"row"}>
                <div
                    className={"col-lg-12 col-md-12 col-sm-24 col-xs-24 section-padding-left section-padding-right"}
                    style={{ paddingLeft: "0" }}
                >
                <br/>
                    <h1 className="title-support-new-design" style={{fontWeight: 500, textAlign: "center"}}>
                        Automate 40% of tickets and chats with human-like <span className="text-highlight">AI Agents</span>
                    </h1>
                    <div className="description-support-new-design w-100" style={{textAlign: "center"}}>
                        Enable your customers and agents to get accurate answers and take <br/>actions on chats and emails. Get started in minutes.
                    </div>
                <br/>
                <div style={{ paddingTop: "3%", paddingBottom: "3%" ,textAlign:"center", display:"flex", gap:"20px", justifyContent:"center"}}>
                   <GetStartedButtonWhite label="BOOK A DEMO" to="/get-a-demo/"
                   />
                   <LearnMoreButton
                    label="TRY FOR FREE"
                    to="/try-for-free/"
                   />
                 </div>

                </div>
                <div className={"col-lg-12 col-md-12 col-sm-24 col-xs-24"} style={{paddingLeft: "12%", paddingRight: "12%", paddingBottom:"5%"}}>
                    <TitleImage />
                </div>
            </div>
        </div>
      </section>
      {/* <!-- Hero Section --> */}

      {/* <!-- End Hero --> */}

      <AutoScrollBanner />


      {/* <!-- End Counts Section --> */}
      {/* <!-- ======= Testimonials Section ======= --> */}
      <Testimonials />
      {/* <!-- End Testimonials Section --> */}
      {/* <!-- ======= Counts Section ======= --> */}
      <CountsSection theme="dark" />
      {/* <!-- ======= Chatbot Section ======= --> */}
      <br/><br/>
     <FeatureSection
            title="AI-Powered Support: Instant, Intelligent Answers with context"
            description=<ul className="checkbox-list">
                  <li style={{ paddingLeft: "20px" }}>
                    With models fine-tuned on your article and ticket data, get AI answers at your fingertips.
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Always available AI agents that provide human-like service
                    </li>
                  </ul>
            imageUrl="/img/Zendesk_website_video.gif"
            buttonLabel="TRY NOW FOR FREE"
            buttonURL="/try-for-free/"
            imageWidth= "100%"
      />

      <FeatureSectionRight
            title="Workflows to automate repetitive tasks, free up time, take actions on behalf of customers"
            description=<ul className="checkbox-list">
                  <li style={{ paddingLeft: "20px" }}>
                    AI agents that go beyond Q&A and can take actions on customer's behalf by connecting with your backend systems
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Automate manual triaging, routing, and tagging, and scale your team
                    </li>
                  </ul>
            imageUrl="/img/homeIlustration3.webp"
            buttonLabel="GET STARTED FOR FREE"
            buttonURL="/get-a-demo/"
            imageWidth= "100%"
      />

      <FeatureSection
            title="Automated ticket tagging, triaging, and routing"
            description=<ul className="checkbox-list">
                  <li style={{ paddingLeft: "20px" }}>
                    IrisAgent discovers tags customized to your domain and automatically tags and routes new tickets
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Set custom answers and actions based on AI-powered intents and tags
                    </li>
                  </ul>
            imageUrl="/img/Tagging_video.gif"
            buttonLabel="TRY NOW FOR FREE"
            buttonURL="/try-for-free/"
            imageWidth= "100%"
      />

      <FeatureSectionRight
            title="Predict and prevent escalations with real-time sentiment insights"
            description=<ul className="checkbox-list">
                  <li style={{ paddingLeft: "20px" }}>
                    Get real-time alerts when customer sentiment drops
                    </li>
                    <li style={{ paddingLeft: "20px" }}>
                    Proactively predict escalations using customer health, sentiment, and revenue signals
                    </li>
                  </ul>
            imageUrl="/img/Sentiment_video.gif"
            buttonLabel="GET STARTED FOR FREE"
            buttonURL="/get-a-demo/"
            imageWidth= "100%"
      />
      {/* <!-- ======= Services Section ======= --> */}
      <AttributeSection></AttributeSection>
      {/* <!-- End Services Section --> */}
            {/*  ======= Cta Section =======  */}
      <TryIrisGPTForFreeBanner />
      {/*  End Cta Section  */}

      {/* <!-- ======= Services Section ======= --> */}
      <ProductSummaryWidget />
      {/* <!-- End Services Section --> */}
      {/* <!-- ======= Services Section ======= --> */}
      <ServiceSection />
      {/* <!-- End Services Section --> */}




      {/* <!-- ======= Integrations Section ======= --> */}
      {/* <IntegrationsSection /> */}
      {/* <!-- End Integrations Section --> */}


    </Layout>
  )
}
